import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, Typography, Theme } from '@material-ui/core';
import SubHeader from '../SubHeader';
import { useParams, useHistory } from 'react-router-dom';
import { theme as koneTheme, theme, InfoModal } from '@konecorp/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ActivityDifferentiator,
  InstallationStatus,
  InstallationSyncData,
  isAssignment,
} from '../../schemas';
import PreCheckListQuestionsList from '../../containers/Pre-ChecklistQuestionsList';
import { useTranslation } from 'react-i18next';
import Context, { InstallationContext } from '../../context';
import { useGetToken } from '../../hooks/useGetToken';
import {
  API_TYPE,
  fetchEmployeeFullName,
  fetchSubcontractors,
  get,
  put,
} from '../../helpers/fetch';
import { Installation } from '../../schemas';
import { getDeviationsData } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useCheckConnection } from '../../hooks/useCheckConnection';
import {
  deleteIndexedDBObject,
  generateIndexedDBKey,
  getIndexedDBObject,
  storedIndexedDBObjectType,
  truncateNetworkFromIndexedDB,
} from '../../helpers/indexedDB';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import FloatingSyncButton from '../FloatingSyncButton';
import { DeviationToSync, manualSyncFromUI } from '../../helpers/deviationOffline';

interface QueryParams {
  networkNumber: string;
}
enum InfoModalType {
  NONE,
  IS_OFFLINE,
  CONFIRMATION,
}
const useStyles = makeStyles((t: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(5),
      maxWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& button': {
        marginTop: theme.spacing(5),
      },
    },
    content: {
      marginLeft: t.spacing(2),
      marginRight: t.spacing(2),
      paddingTop: t.spacing(3),
      height: '100%',
    },
    greenHeadline: {
      backgroundColor: t.palette.success.light,
      padding: t.spacing(1),
    },
    blueHeadline: {
      backgroundColor: t.palette.info.light,
      padding: t.spacing(1),
    },
    grayHeadline: {
      backgroundColor: '#E0E0E0',
      padding: t.spacing(1),
    },
    assigmentInfo: {
      paddingLeft: t.spacing(3),
      paddingBottom: t.spacing(1),
      paddingTop: t.spacing(1),
    },
    extraInfo: {
      margin: theme.spacing(5),
      maxWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    buttonRow: {
      paddingLeft: 0,
    },
    upperCase: {
      textTransform: 'uppercase',
    },
    closeDialogIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      zIndex: 1,
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
    },
  })
);

const PreChecklist = (): JSX.Element => {
  const history = useHistory();
  const [isOnline] = useCheckConnection();
  const [questions, setQuestions] = useState([]);
  const { dispatch } = useContext(InstallationContext);
  const [installerNames, setInstallerNames] = useState<string[]>([]);
  const [confirmationDialogOpen, setOpenConfimationDialog] = useState<boolean>(false);
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const {
    installationData,
    updateIsLoading,
    updateInstallationData,
    updateNetworkNumber,
  } = useContext(Context);
  const [getTokenFunction] = useGetToken();
  const { t } = useTranslation();
  const { networkNumber } = useParams() as QueryParams;
  const classes = useStyles(koneTheme);
  const [dialogType, setDialogType] = useState<InfoModalType>(InfoModalType.NONE);
  const [supervisorFullName, setSupervisorFullName] = useState<string>('');
  const [isSubcontractor] = useIfSubcontractor();

  const handleCloseConfirmationDialog = async () => {
    try {
      updateIsLoading(true);
      await updateInstallationListsInContext();
      updateIsLoading(false);
      history.push('/tobestarted');
    } catch (e) {
      updateIsLoading(false);
      console.error('error when fetching installation lists', e);
    }
  };
  const getInstallerNames = async () => {
    const accessToken = await getTokenFunction();
    const subcontractors = await fetchSubcontractors(networkNumber, accessToken);
    const subcontractorInstallers = subcontractors.filter(
      (subcontractor) =>
        subcontractor.activityDifferentiator === ActivityDifferentiator.INST
    );
    const latestInstallers = await get(
      `v1/installations/${networkNumber}/?getBy=networkNumber`,
      accessToken
    );
    const koneInstaller = latestInstallers.assignees.filter(
      (worker: { activityDifferentiator: ActivityDifferentiator }) =>
        worker.activityDifferentiator === ActivityDifferentiator.INST &&
        !('subcontractor' in worker)
    );
    const installers = [...koneInstaller, ...subcontractorInstallers];
    const installerNamePromises = installers.map((worker) =>
      isAssignment(worker)
        ? fetchEmployeeFullName(worker.koneResourcePersonalNumber, accessToken)
        : Promise.resolve(worker.subcontractor.name)
    );
    return await Promise.all(installerNamePromises);
  };

  const [currentUserRole] = useGetCurrentUserRole();
  useEffect(() => {
    const fetchData = async () => {
      let fetchedDeviations;
      try {
        if (networkNumber) {
          updateIsLoading(true);
          const accessToken = await getTokenFunction();
          const data: Installation | null = await get(
            `v1/installations/${networkNumber}`,
            accessToken
          );

          if (data) {
            updateInstallationData(data);
            updateNetworkNumber(networkNumber);

            const supervisorFullName = installationData?.supervisorNumber
              ? await fetchEmployeeFullName(
                  installationData.supervisorNumber,
                  accessToken
                )
              : '';
            setSupervisorFullName(supervisorFullName);
          }

          fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
          let filteredDeviations = [];
          if (currentUserRole === ActivityDifferentiator.INST) {
            filteredDeviations = fetchedDeviations.filter(
              (deviation) =>
                deviation.installationWorkflowStatus ===
                  InstallationStatus.TO_BE_STARTED ||
                deviation.installationWorkflowStatus ===
                  InstallationStatus.FOR_INSTALLER_ACCEPTANCE
            );
          } else {
            filteredDeviations = fetchedDeviations.filter(
              (deviation) =>
                deviation.installationWorkflowStatus === InstallationStatus.TO_BE_STARTED
            );
          }
          dispatch({
            type: InstallationActionName.SET_DEVIATIONS,
            deviations: filteredDeviations,
          });
        }
      } catch (e) {
        console.error(
          'Error while fetching installation data or questions or deviations',
          e
        );
      } finally {
        updateIsLoading(false);
      }
    };

    fetchData();
  }, [networkNumber, history, currentUserRole]);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (installationData && currentUserRole) {
        try {
          const accessToken = await getTokenFunction();
          const questions = await get(
            `v1/pre-install-checklist?role=${currentUserRole}`,
            accessToken
          );
          setQuestions(questions);

          if (questions && questions.length > 1 && questions[1].questionId) {
            const newUrl = `${history.location.pathname}?questionSetId=${
              questions[1].questionId.split('#')[1]
            }`;

            history.replace(newUrl);
          }
        } catch (e) {
          console.error('Error while fetching questions', e);
        }
      }
    };

    fetchQuestions();
  }, [installationData, currentUserRole]);

  const handleSendInstallationButtonClick = async (): Promise<void> => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await syncData(networkNumber, accessToken);

      await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          status: InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
        }
      );
      const installersNames = await getInstallerNames();
      setInstallerNames(installersNames);
      setOpenConfimationDialog(true);
    } catch (error) {
      console.error('error while updating installation status', error);
    } finally {
      updateIsLoading(false);
    }
  };

  const onStartingInstallation = async () => {
    try {
      if (!isOnline) {
        setDialogType(InfoModalType.IS_OFFLINE);
        return;
      }

      updateIsLoading(true);
      const accessToken = await getTokenFunction();
      await syncData(networkNumber, accessToken);

      await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        { status: InstallationStatus.INSTALLER_ACCEPTED }
      );

      await truncateNetworkFromIndexedDB(networkNumber);

      setDialogType(InfoModalType.CONFIRMATION);
    } catch (e) {
      console.error('Error when trying to start an installation, error: ', e);
    } finally {
      updateIsLoading(false);
    }
  };

  async function syncData(networkNumber: string, accessToken: string) {
    try {
      const syncDataKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.SYNC_DATA
      );

      const deviationToSyncKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
      );

      const syncDataFromIndexedDb = await getIndexedDBObject<InstallationSyncData>(
        syncDataKey
      );

      const deviationSyncDataFromIndexedDB = await getIndexedDBObject<DeviationToSync[]>(
        deviationToSyncKey
      );
      if (syncDataFromIndexedDb) {
        await put(
          `v1/installations/${networkNumber}/sync`,
          accessToken,
          API_TYPE.APPLICATION,
          { ...syncDataFromIndexedDb, currentUserRole }
        );
      }
      if (deviationSyncDataFromIndexedDB?.length) {
        await manualSyncFromUI(networkNumber, accessToken);
      }
    } catch (e) {
      console.error('Error while navigating');
    } finally {
      await deleteIndexedDBObject(
        generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
      );
      await deleteIndexedDBObject(
        generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.DEVIATIONS_TO_SYNC)
      );
    }
  }

  const getDialogMessage = (): string => {
    if (dialogType === InfoModalType.IS_OFFLINE) {
      return t('installationStarting.onlyOnline');
    } else {
      return t('installationStarting.networkStartedMessageWithSupervisorFullName', {
        supervisorFullName: supervisorFullName,
      });
    }
  };

  const onDialogClose = (): void => {
    if (dialogType === InfoModalType.IS_OFFLINE) {
      setDialogType(InfoModalType.NONE);
    } else {
      installationData?.networkTag === 'MOD'
        ? openInstallationTasks()
        : openInstallationExecution();
    }
  };

  const openInstallationExecution = () => {
    // hard reload the app so when the app go to /execution it has a clean context
    // and trigger the caching again
    if (isSubcontractor) {
      window.location.replace(`/subcontractor/${networkNumber}/execution`);
    } else {
      window.location.replace(`/${networkNumber}/execution`);
    }
  };

  const openInstallationTasks = () => {
    // hard reload the app so when the app go to /execution it has a clean context
    // and trigger the caching again
    if (isSubcontractor) {
      window.location.replace(`/subcontractor/${networkNumber}/installation-tasks`);
    } else {
      window.location.replace(`/${networkNumber}/installation-tasks`);
    }
  };

  const handleGoBackClick = async () => {
    updateIsLoading(true);
    const accessToken = await getTokenFunction();
    await syncData(networkNumber, accessToken);
    updateIsLoading(false);
    history.goBack();
  };

  return (
    <>
      <SubHeader title={networkNumber} handleGoBackClick={handleGoBackClick} />
      {installationData?.preInstallCheckListAnswers && currentUserRole && (
        <>
          <PreCheckListQuestionsList
            questions={questions}
            answers={installationData?.preInstallCheckListAnswers}
            role={currentUserRole}
          />

          <Grid item className={classes.extraInfo} data-testid="pre-checklist">
            {currentUserRole === ActivityDifferentiator.SPV ? (
              <Button
                size="large"
                data-testid="send-installation-acceptance-button"
                disabled={
                  (installationData?.preInstallCheckListAnswers?.[0]?.answers?.filter(
                    (answer) => answer !== null
                  ).length || 0) !==
                  questions.length - 1
                }
                variant="contained"
                color="primary"
                onClick={handleSendInstallationButtonClick}
              >
                <Typography>
                  {' '}
                  {t('supervisorNewInstallation.sendInstallationForAcceptance')}{' '}
                </Typography>
              </Button>
            ) : (
              <Button
                size="large"
                data-testid="start-button"
                disabled={
                  (installationData?.preInstallCheckListAnswers?.[0]?.answers?.filter(
                    (answer) => answer !== null
                  ).length || 0) !==
                  questions.length - 1
                }
                variant="contained"
                color="primary"
                onClick={onStartingInstallation}
              >
                {t('installationStarting.startInstallation')}
              </Button>
            )}

            <InfoModal
              closeButtonText={t('supervisorNewInstallation.OK')}
              message={t('supervisorNewInstallation.markedMessage', {
                installerNames: installerNames.join(', '),
              })}
              open={confirmationDialogOpen}
              onClose={handleCloseConfirmationDialog}
              isCenteredMessage
            />

            <InfoModal
              closeButtonText={t('installationStarting.ok')}
              message={getDialogMessage()}
              open={
                dialogType === InfoModalType.CONFIRMATION ||
                dialogType === InfoModalType.IS_OFFLINE
              }
              onClose={onDialogClose}
              isCenteredMessage
            />
          </Grid>
          <FloatingSyncButton />
        </>
      )}
    </>
  );
};

export default PreChecklist;
