import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Context from '../../context';
import Empty from '../../components/Empty';
import Handover from '../Handover';
import { Box } from '@material-ui/core';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';

const InstallationPlanHandover = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isSubcontractor] = useIfSubcontractor();
  const questionSequence = queryParams.get('questionSequence') || 0;
  const { networkNumber } = useContext(Context);

  const renderQuestionData = () => {
    const rootPath = isSubcontractor ? '/subcontractor' : '';
    return (
      <Handover
        onNavigateBackward={() => {
          history.push(`${rootPath}/${networkNumber}/installation-tasks`);
        }}
        networkNumber={networkNumber}
      />
    );
  };

  return (
    <Box data-testid="installation-plan-handover">
      {questionSequence ? (
        renderQuestionData()
      ) : (
        <Empty displayIcon={false} message={t('installationPlanList.noHandoverData')} />
      )}
    </Box>
  );
};

export default InstallationPlanHandover;
