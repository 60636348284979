import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Calendar } from '@konecorp/ui-library';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../helpers/formating';

export type DatePickerProps = {
  name?: string;
  dateTime: string;
  disabled?: boolean;
  onDateSelected: (ISOFormattedDate: string) => void;
  label?: string;
  newDate?: boolean;
  setNewDate?: (newDate: boolean) => void;
};

const useStyles = makeStyles<Theme, Partial<DatePickerProps>>(() => ({
  datePicker: {
    display: 'block',
    width: '46px',
    height: '24px',
    borderWidth: 0,
    outline: 'none',
    background: 'transparent',
    fontSize: '100px',
  },
  dateIcon: {
    margin: '0px',
    position: 'absolute',
    left: '0px',
    top: '0px',
    zIndex: -1,
    opacity: ({ disabled }) => (disabled ? '0.5' : '1'),
  },
}));

const isValidDateTime = (dateTimeString: string) => {
  return !isNaN(Date.parse(dateTimeString));
};

const toISODateStringWithoutTime = (isoDateTime: string): string => {
  /* ISO Date time has a format: 2023-11-14T15:01:00.102Z
     but date picker <input> only accepts yyyy-MM-dd
  */
  return isoDateTime.split('T')[0];
};

export const DatePicker = ({
  name = 'kone-date-picker',
  dateTime,
  disabled = false,
  onDateSelected,
  label,
  newDate,
  setNewDate,
}: DatePickerProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles({ disabled });

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    if (selectedDate !== '') {
      setNewDate && setNewDate(false);
      onDateSelected(new Date(selectedDate).toISOString());
    }
  };

  const minSelectedDate = toISODateStringWithoutTime(new Date(Date.now()).toISOString());

  if (!isValidDateTime(dateTime)) {
    return (
      <Typography component="h6" color="error">
        {t('datePicker.invalidDateFormat')}
      </Typography>
    );
  }

  return (
    <>
      {label && label !== 'undefined' ? (
        <Box position="relative" zIndex="9">
          <Link>
            <input
              type="date"
              name={name}
              min={minSelectedDate}
              value={
                dateTime
                  ? toISODateStringWithoutTime(dateTime as string)
                  : minSelectedDate
              }
              onChange={handleChangeDate}
              className={classes.datePicker}
              aria-label={`${name}-input`}
              disabled={disabled}
            />
            {newDate ? (
              <Typography className={classes.dateIcon}>+ Add Date</Typography>
            ) : (
              <Typography className={classes.dateIcon}>{formatDate(dateTime)}</Typography>
            )}
          </Link>
        </Box>
      ) : (
        <Box position="relative" zIndex="9">
          <input
            type="date"
            name={name}
            min={minSelectedDate}
            value={
              dateTime ? toISODateStringWithoutTime(dateTime as string) : minSelectedDate
            }
            onChange={handleChangeDate}
            className={classes.datePicker}
            aria-label={`${name}-input`}
            disabled={disabled}
          />
          <Calendar className={classes.dateIcon} />
        </Box>
      )}
    </>
  );
};
