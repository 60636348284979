import {
  AuthenticatorProps,
  Navigation,
  theme,
  WithAuthenticator,
  WithAuthenticatorProps,
} from '@konecorp/ui-library';
import { Box, CssBaseline } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { generatePath, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import Context from '../../context';
import { ActivityDifferentiator, InstallationStatus } from '../../schemas';
import { useSetLanguage } from '../../hooks/useSetLanguage';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useSetServiceWorkerMessageInterval } from '../../hooks/useSetServiceWorkerMessageInterval';
import getMenus from '../../menus';
import ConnectStatusBanner from '../../components/ConnectStatusBanner';
import Installations from '../Installations';
import MyInstallationsList from '../../components/MyInstallationsList';
import OngoingInstallationsList from '../../components/OngoingInstallationsList';
import ToBeStartedInstallationsList from '../../components/ToBeStartedInstallationsList';
import Newinstallation from '../NewInstallation';
import NetworkInstallation, { NetworkInstallationPaths } from '../NetworkInstallation';
import Attachment from '../Attachment';
import Dashboard from '../../components/Dashboard';
import DemoUtilitiesPage from '../../containers/DemoUtilitiesPage';
import Loading from '../../components/Loading';
import SelfAssign from '../../containers/SelfAssign';
import MaintenanceSelfAssign from '../MaintenanceSelfAssign';
import AdminPage from '../../containers/AdminPage';
import InstallationState from '../../context/installation';
import { compareStatus, CompareStatusResult } from '../../helpers/getInstallationLists';
import ErrorMessageDialog from '../../components/ErrorMessageDialog';
import SupervisorChecklist from '../../components/Pre-Checklist';
import InstallationPlan from '../../components/InstallationPlan';

const APP_URL = process.env.REACT_APP_IAPP_URL || '';
const NAVIGATION_HEIGHT = theme.typography.fontSize * 6;

const useStyles = makeStyles((t: Theme) =>
  createStyles({
    loadingBackdrop: {
      zIndex: 1500,
    },
    // necessary for content to be below app bar
    toolbar: t.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    footer: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      '& span.Mui-selected': {
        fontSize: 12,
      },
    },
  })
);

const KoneEmployeeApp = (
  props: PropsWithChildren<WithAuthenticatorProps>
): JSX.Element => {
  const {
    authState: { isAuthenticated },
    installationData,
    networkNumber,
    updateAuthState,
    updateNetworkNumber,
    updateInstallationData,
    updateAcquireToken,
    updateIsLoading,
    isLoading,
  } = useContext(Context);

  useSetLanguage();

  //Setting up interval to send message to service worker every 5 mins to sync with backend
  useSetServiceWorkerMessageInterval();

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles(theme);
  const [userRole] = useGetCurrentUserRole();
  const redirectUrl = window.sessionStorage.getItem('redirectUrl');

  const [referrerUrl, setReferrerUrl] = useState(
    window.sessionStorage.getItem('referrerUrl')
  );

  /* This needed because Azure AD perform redirection and we need to know
     what was an URL user want to access
  */
  useEffect(() => {
    const { pathname, search } = location;

    if (pathname.length > 1 && !isAuthenticated) {
      window.sessionStorage.setItem('redirectUrl', `${pathname}${search}`);
    }
    if (pathname.length === 1 && isAuthenticated) {
      if (redirectUrl) {
        history.push(redirectUrl);
      }
    }
    if (pathname.length > 1 && isAuthenticated) {
      window.sessionStorage.removeItem('redirectUrl');
    }
  }, [location, isAuthenticated, history]);

  const isDeviationsOnlyMode =
    userRole === ActivityDifferentiator.SEEN &&
    installationData?.status &&
    compareStatus(installationData.status, InstallationStatus.FOR_SEB_ACCEPTANCE) ===
      CompareStatusResult.AFTER;

  const isPreChecklistMode =
    userRole === ActivityDifferentiator.SPV &&
    installationData?.status &&
    compareStatus(installationData.status, InstallationStatus.TO_BE_STARTED) ===
      CompareStatusResult.SAME;

  useEffect(() => {
    if (isDeviationsOnlyMode)
      history.push(generatePath(NetworkInstallationPaths.DEVIATIONS, { networkNumber }));
  }, [networkNumber, isDeviationsOnlyMode]);

  useEffect(() => {
    const updateLoginData = async (): Promise<void> => {
      try {
        const authResponse = await props.acquireToken(true);
        updateAcquireToken(() => props.acquireToken); //store the function

        // The authResponse (try to call refresh token) can fail in slow network or offline mode
        // which is the reason of ? and passing in empty string. However, this following function, updateGraphData
        // is the one who set authState.isAuthenticated to true or false, if the user already logged-in and there is a cache
        // it will result in a true to isAuthenticated, if there is NO cache aka user never login before, it will force user to login like usual
        // read the updateGraphData detail implementation and service-worker.js for more information
        await props.updateGraphData(authResponse?.accessToken || '');

        const authState = props.getAuthState();
        updateAuthState(authState);
      } catch (error) {
        console.error('updateLoginData', error);
      }
    };

    if (
      !referrerUrl &&
      document.referrer &&
      process.env.REACT_APP_IAPP_URL !== document.referrer
    ) {
      const url = document.referrer;

      setReferrerUrl(url);
      window.sessionStorage.setItem('referrerUrl', url);
    }
    if (!isAuthenticated) updateLoginData();
  }, [isAuthenticated, referrerUrl, props, updateAuthState]);

  const redirectToReferrerUrl = async () => {
    /* DO NOT CHANGE the order of functions calling. All the function must be call after redirect
       otherwise it will cause the child component which use this function unnecessary re-render
       because of shared context
    */
    const currentPreviousPageInternalUrl =
      window.sessionStorage.getItem('previousPageInternalUrl') ?? '/';
    history.push(currentPreviousPageInternalUrl);

    updateIsLoading(true);
    updateNetworkNumber('');
    updateInstallationData(null);
    updateIsLoading(false);
  };

  const showMyPlan =
    (!installationData?.isModelData && !installationData?.isTacoDataQuest) ||
    installationData?.installerQuestionSetSequence.length === 2;
  const selectedMenus = () =>
    getMenus(
      networkNumber,
      installationData?.status || InstallationStatus.TO_BE_STARTED,
      userRole,
      false,
      showMyPlan,
      installationData
    );

  const handleMenuChange = (menuTitle: string): void => {
    if (!menuTitle) {
      return;
    }

    const selectedMenu = selectedMenus().find(
      (item) => item.title === menuTitle.trimEnd()
    );

    if (selectedMenu?.url === location.pathname) {
      return;
    }

    history.push(selectedMenu?.url || '/');
  };

  const getCurrentMenuPageIndex = (url: string): number => {
    switch (true) {
      case url.includes('progress'):
      case url.includes('myday'):
      case url.includes('installation-tasks'):
      case url.includes('starting') && installationData?.networkTag === 'MOD':
        return 0;
      case url.includes('starting'):
      case url.includes('execution'):
        return 1;
      case url.includes('deviations'):
        return 2;
      default:
        return 0;
    }
  };

  const initialSelectedMenuIndex = getCurrentMenuPageIndex(
    redirectUrl || location.pathname
  );

  return (
    <>
      {(isLoading || !isAuthenticated) && (
        <Loading backdropClassName={classes.loadingBackdrop} />
      )}
      <ErrorMessageDialog />
      {isAuthenticated && (
        <>
          <Box display="flex" pb={11}>
            <CssBaseline />
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <ConnectStatusBanner />
              <Switch>
                <Route path="/" exact>
                  <Installations onSignOut={props.onSignOut} />
                </Route>
                <Route path="/tobestarted">
                  <ToBeStartedInstallationsList />
                </Route>
                <Route path="/ongoing" exact>
                  <OngoingInstallationsList />
                </Route>
                <Route path="/completed" exact>
                  <MyInstallationsList />
                </Route>
                <Route path="/selfassign" exact>
                  <SelfAssign />
                </Route>
                <Route path="/maintenanceassign">
                  <MaintenanceSelfAssign />
                </Route>
                <Route path="/:networkNumber/newinstallation" exact>
                  <Newinstallation />
                </Route>
                <Route path="/:networkNumber/installation-plan" exact>
                  <InstallationState>
                    <InstallationPlan />
                  </InstallationState>
                </Route>
                <Route path="/:networkNumber/pre-install-checklist" exact>
                  <InstallationState>
                    <SupervisorChecklist />
                  </InstallationState>
                </Route>
                <Route exact path={Object.values(NetworkInstallationPaths)}>
                  <InstallationState>
                    <NetworkInstallation redirectToReferrerUrl={redirectToReferrerUrl} />
                  </InstallationState>
                </Route>
                <Route path="/attachment">
                  <Attachment />
                </Route>
                <Route path="/dashboard/:countryCode?">
                  <Dashboard />
                </Route>
                <Route path="/demo">
                  {/* disable /demo in PROD is handle within DemoUtilitiesPage component  */}
                  <DemoUtilitiesPage />
                </Route>
                <Route path="/admin">
                  <AdminPage />
                </Route>
              </Switch>
            </main>
          </Box>
          {networkNumber && !isDeviationsOnlyMode && !isPreChecklistMode && (
            <footer data-testid="footer" className={classes.footer}>
              <Navigation
                data={selectedMenus()}
                handleActionButtonClick={handleMenuChange}
                height={NAVIGATION_HEIGHT}
                initalSelectedMenuIndex={initialSelectedMenuIndex}
                shadow
              />
            </footer>
          )}
        </>
      )}
    </>
  );
};
export { KoneEmployeeApp as PureComponent };

export default WithAuthenticator(KoneEmployeeApp, {
  clientId: 'c49d3833-ee3c-4bfa-8fa8-d7566b7ec0fd',
  authority: 'https://login.microsoftonline.com/2bb82c64-2eb1-43f7-8862-fdc1d2333b50',
  redirectUri: APP_URL,
  validateAuthority: true,
  postLogoutRedirectUri: APP_URL,
  navigateToLoginRequestUrl: false,
} as AuthenticatorProps);
