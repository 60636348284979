import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';

import Context from '../../context';
import {
  ActivityDifferentiator,
  Attachment,
  Deviation,
  Installation,
} from '../../schemas';
import { getUnansweredQuestionsCount } from '../../helpers/question';
import HandoverNotifications from '../HandoverNotifications';
import UploadWidget from '../UploadWidget';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';

const useStyles = makeStyles((theme) =>
  createStyles({
    ready: {
      textAlign: 'center',
      '& .handoverAssignee': {
        display: 'block',
        width: '100%',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
      },
    },
    textInput: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    //This help the checkbox always align on the first line of the label
    checkBox: {
      display: 'flex',
      alignItems: 'flex-start',
      '& h6': {
        paddingTop: theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
      },
    },
  })
);

export type NonSupervisorSignoffProps = {
  commentText: string;
  deviations?: Deviation[] | undefined;
  handoverAssignee: string;
  selectedFiles: File[];
  installation: Installation | null;
  setCommentText: (comment: string) => void;
  deleteAttachmentsLocally: (file: File | Attachment) => void;
  handleUploadButton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setHandoverAllowed: (allowed: boolean) => void;
};

const NonSupervisorSignoff = (props: NonSupervisorSignoffProps): JSX.Element => {
  const {
    commentText,
    deleteAttachmentsLocally,
    deviations,
    handleUploadButton,
    handoverAssignee,
    installation,
    selectedFiles,
    setCommentText,
    setHandoverAllowed,
  } = props;
  const { installationData } = useContext(Context);
  const classes = useStyles();
  const { t } = useTranslation();
  const [userRole] = useGetCurrentUserRole();

  const [proceedChecked, setProceedChecked] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setHandoverAllowed(true);
    };
  }, []);

  const settingsForCurrentStatus = installationData?.status
    ? installationData.settings.handover?.[installationData.status]
    : undefined;

  const unansweredQuestionsCount = getUnansweredQuestionsCount({
    questions: installationData?.installerQuestions ?? [],
    answers: installationData?.installerAnswers ?? [],
  });
  const isModelData = installationData?.isModelData ?? false;
  const hasInstallerUnansweredQuestions =
    userRole === ActivityDifferentiator.INST && unansweredQuestionsCount > 0;

  const acknowledgedUnansweredInstallerQuestions =
    commentText.trim().length > 0 && proceedChecked;

  const requiredQuestionsActions =
    !hasInstallerUnansweredQuestions ||
    (hasInstallerUnansweredQuestions && acknowledgedUnansweredInstallerQuestions);

  const requiredAttachmentsAdded =
    selectedFiles.length >= (settingsForCurrentStatus?.totalMandatoryAttachments || 0);

  const isHandoverAllowed =
    (isModelData || commentText.trim().length > 0) &&
    requiredQuestionsActions &&
    requiredAttachmentsAdded;

  useEffect(() => {
    setHandoverAllowed(isHandoverAllowed);
  }, [installationData, commentText, selectedFiles, proceedChecked]);

  const uploadWidgetLabel =
    t(
      settingsForCurrentStatus?.attachmentQuestionTranslationKey ||
        'handoverSignoffPage.uploadButtonInfoText'
    ) + (requiredAttachmentsAdded ? '' : ' *');

  return (
    <Box component="section" data-testid="handover-signoff">
      <p className={classes.ready} role="note">
        <Trans
          i18nKey="handoverSignoffPage.pleaseReady"
          values={{ handoverAssignee }}
          components={{
            span: <span className="handoverAssignee" />,
          }}
        />
      </p>
      <Box mb={1}>
        <HandoverNotifications
          deviations={deviations}
          installation={installation}
          showQdPlusQuestionsWarning={true}
          showQualityReviewQuestionsWarning={userRole !== ActivityDifferentiator.INST}
        />
      </Box>
      <Box mt={1} mb={1}>
        {hasInstallerUnansweredQuestions && (
          <FormControl>
            <FormControlLabel
              className={classes.checkBox}
              value="proceed"
              control={
                <Checkbox
                  data-testid="proceed-checkbox"
                  checked={proceedChecked}
                  color="primary"
                  name="proceed"
                  onChange={(_, checked) => setProceedChecked(checked)}
                />
              }
              label={
                installation?.networkTag !== 'MOD' ? (
                  <Typography variant="h6">
                    {t('handoverSignoffPage.proceedWithUnansweredQuestions')}
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    {t('handoverSignoffPage.proceedWithAnsweredQuestions')}
                  </Typography>
                )
              }
            />
          </FormControl>
        )}
      </Box>
      <Box mt={1} mb={1}>
        {installation?.networkTag !== 'MOD'
          ? hasInstallerUnansweredQuestions
            ? t('handoverSignoffPage.whyUnansweredQuestions')
            : t('handoverSignoffPage.canDocument')
          : t('handoverSignoffPage.installerHandoverTextPartialMod')}
      </Box>
      <TextField
        className={classes.textInput}
        placeholder={t('handoverSignoffPage.commentPlaceholder')}
        id="standard-multiline-static"
        value={commentText}
        variant="outlined"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setCommentText(event.target.value)
        }
        multiline
        rows={4}
        inputProps={{
          'data-testid': 'comment-input',
        }}
      />

      <UploadWidget
        handleUploadButton={handleUploadButton}
        deleteAttachmentsLocally={deleteAttachmentsLocally}
        selectedFiles={selectedFiles}
        label={uploadWidgetLabel}
      />
    </Box>
  );
};
export default NonSupervisorSignoff;
