import React, { useEffect, useContext, useState } from 'react';
import { CircleCancel, theme } from '@konecorp/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InstallationCard from '../InstallationCard';
import SubHeader from '../../components/SubHeader';
import Context from '../../context';
import {
  ActivityDifferentiator,
  InstallationCardData,
  Installation,
} from '../../schemas';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { useGetToken } from '../../hooks/useGetToken';
import {
  getInstallationCardData,
  sortInstallationsCardData,
} from '../../helpers/getInstallationLists';
import { getKoneEmployeeUserRole } from '../../helpers/getUserRole';
import { useGetUserData } from '../../hooks/useGetUserData';
import { Box, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { DialogType, Filters, defaultFilters } from '../OngoingInstallationsList';
import { FilterList } from '@material-ui/icons';
import NetworkListFilters from '../NetworkListFilters';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      overflow: 'auto',
    },
    headingContainer: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    body: {
      margin: theme.spacing(1),
    },
    closeIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      right: '10px',
    },
  })
);

const ToBeStartedInstallationsList = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [isSubcontractor] = useIfSubcontractor();
  const [employeeId] = useGetUserData();
  const [getTokenFunction] = useGetToken();
  const [openedDialog, setOpenedDialog] = useState<DialogType>(DialogType.NONE);
  const [installationCardData, setInstallationCardData] = useState<
    InstallationCardData[]
  >([]);
  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
  });
  const { toBeStartedInstallations, updateIsLoading } = useContext(Context);
  const filtersCloseAction = (reset?: boolean) => {
    if (reset) setFilters(defaultFilters);
    setOpenedDialog(DialogType.NONE);
  };
  useEffect(() => {
    (async () => {
      if (!toBeStartedInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (toBeStartedInstallations && installationCardData.length === 0) {
        updateIsLoading(true);

        const token = await getTokenFunction();
        const promises = toBeStartedInstallations.map((network) =>
          getInstallationCardData(network, token)
        );

        const toBeStartedInstallationCardData = await Promise.all(promises);
        setInstallationCardData(
          toBeStartedInstallationCardData.sort(sortInstallationsCardData)
        );
        updateIsLoading(false);
      }
    })();
  }, [toBeStartedInstallations]);

  const onInstallationCardClick = (installationData: InstallationCardData) => {
    const releaseNetwork = !(
      installationData.network.isModelData && !installationData.network.isTacoDataQuest
    );
    if (releaseNetwork) {
      // If no taco data no need to start installation
      window.sessionStorage.setItem('previousPageInternalUrl', '/tobestarted');

      const userRole = getKoneEmployeeUserRole({
        installationData: installationData.installation as Installation,
        employeeId,
      });

      if (userRole === ActivityDifferentiator.SPV) {
        history.push(
          `${installationData.network.networkNumber}/newinstallation?role=${ActivityDifferentiator.SPV}`
        );
        return;
      }

      const rootPath = isSubcontractor ? '/subcontractor' : '';
      history.push(`${rootPath}/${installationData.network.networkNumber}/starting`);
    }
  };

  return (
    <>
      <SubHeader
        title={t('myInstallation.toBeStartedInstallations')}
        handleGoBackClick={() => {
          history.push('/');
        }}
      />
      {true && (
        <Grid container className={classes.headingContainer}>
          <Grid item xs={11}>
            <Typography variant={'h5'}>{t('installationNetworkListTitle')}</Typography>
          </Grid>
          <Grid item xs={1}>
            <FilterList
              data-testid="icon-filters"
              onClick={() => setOpenedDialog(DialogType.FILTERS)}
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={openedDialog !== DialogType.NONE} fullWidth maxWidth="sm">
        <DialogContent>
          <CircleCancel
            data-testid="dialog-close-icon"
            className={classes.closeIcon}
            onClick={() => setOpenedDialog(DialogType.NONE)}
          />
          <Box mt={1} padding={theme.spacing(0.5)}>
            {openedDialog === DialogType.FILTERS && (
              <NetworkListFilters
                filters={filters}
                setFilters={setFilters}
                onClose={filtersCloseAction}
              />
            )}
          </Box>{' '}
        </DialogContent>
      </Dialog>
      <div className={classes.content}>
        {installationCardData &&
          installationCardData
            .filter((cardData) => {
              const { networkTag } = cardData.network || {};
              if (cardData.network.isVariationOrder) return false;
              if (filters.NBS && (networkTag === 'NBS' || networkTag === '')) return true;
              if (filters.MOD && networkTag === 'MOD') return true;
              if (
                filters &&
                networkTag !== 'NBS' &&
                networkTag !== 'MOD' &&
                networkTag !== ''
              )
                return true;
              return false;
            })
            .map((installationData: InstallationCardData, index: number) => {
              const { network, installation, deviations } = installationData;

              return (
                <InstallationCard
                  key={`tobestarted-installation-card-${index}`}
                  network={network}
                  deviations={deviations}
                  status={installation.status}
                  role={getKoneEmployeeUserRole({
                    installationData: installation as Installation,
                    employeeId,
                  })}
                  firstAssignmentDate={installation.firstAssignmentDate}
                  latestAnsweredQdQuestionSet={installation.latestAnsweredQdQuestionSet}
                  hasQualityForm={installation.hasQualityForm}
                  onClick={() => onInstallationCardClick(installationData)}
                />
              );
            })}
      </div>
    </>
  );
};

export default ToBeStartedInstallationsList;
