import React from 'react';
import i18next from 'i18next';
import { TabMyDay, TabMyDeviations, TabQD, TabMyPlan, theme } from '@konecorp/ui-library';
import { ActivityDifferentiator, Installation, InstallationStatus } from './schemas';

export interface MenuProps {
  title: string;
  icon: JSX.Element;
  description: string;
  url: string;
}

const styles = {
  icon: {
    fontSize: theme.typography.fontSize * 3.3,
    margin: theme.spacing(1),
  },
};
//Bottom navigation menus - myday , qd+/qd review, deviations
/**
 * @param networkNumber -
 * @param status - InstallationStatus example : toBeStarted,forInstallerAcceptance,testerRejected... etc
 * @param role - INST =  Installer, CMSN = Tester, SPV = NEB Supervisor,SEBSPV =  SEB Supervisor, SEEN =  NEB Service Engineer,  OTHER
 * @param isSubcontractor - true/false
 * @returns - Object with details related to the menuItems : title,icon,description,url
 */
const getMenus = (
  networkNumber: string,
  status: InstallationStatus,
  role?: ActivityDifferentiator,
  isSubcontractor?: boolean,
  showMyPlan?: boolean,
  installationData?: Installation | null
): MenuProps[] => {
  const subcontractorPrefix = isSubcontractor ? '/subcontractor' : '';

  const progress: MenuProps = {
    title: i18next.t('menu.progress.title').toUpperCase(),
    description: i18next.t('menu.progress.description'),
    icon: <TabMyDay style={styles.icon} />,
    url: `${subcontractorPrefix}/${networkNumber}/progress`,
  };

  const myday: MenuProps = {
    title: i18next.t('menu.myday.title').toUpperCase(),
    description: i18next.t('menu.myday.description'),
    icon: <TabMyDay style={styles.icon} />,
    url: `${subcontractorPrefix}/${networkNumber}/myday`,
  };
  const myplan: MenuProps = {
    title: i18next.t('menu.myplan.title').toUpperCase(),
    description: i18next.t('menu.myplan.description'),
    icon: <TabMyPlan style={styles.icon} />,
    url: `${subcontractorPrefix}/${networkNumber}/myday`,
  };

  const starting: MenuProps = {
    title: i18next.t('menu.starting.title').toUpperCase(),
    description: i18next.t('menu.starting.description'),
    icon: <TabQD style={styles.icon} />,
    url: `${subcontractorPrefix}/${networkNumber}/starting`,
  };

  const tasks: MenuProps = {
    title: i18next.t('menu.tasks.title').toUpperCase(),
    description: i18next.t('menu.tasks.description'),
    icon: <TabQD style={styles.icon} />,
    url:
      status === InstallationStatus.INSTALLER_ACCEPTED &&
      installationData?.networkTag === 'MOD'
        ? `${subcontractorPrefix}/${networkNumber}/installation-tasks`
        : `${subcontractorPrefix}/${networkNumber}/execution`,
  };

  const deviations: MenuProps = {
    title: i18next.t('menu.deviations.title').toUpperCase(),
    icon: <TabMyDeviations style={styles.icon} />,
    description: i18next.t('menu.deviations.description'),
    url: `${subcontractorPrefix}/${networkNumber}/deviations`,
  };

  switch (role) {
    case ActivityDifferentiator.SPV:
      return [progress, tasks, deviations];
    case ActivityDifferentiator.INST: {
      const menu =
        status === InstallationStatus.FOR_INSTALLER_ACCEPTANCE &&
        installationData?.networkTag === 'MOD'
          ? [starting, deviations]
          : status === InstallationStatus.FOR_INSTALLER_ACCEPTANCE
          ? [showMyPlan ? myplan : myday, starting, deviations]
          : status === InstallationStatus.INSTALLER_ACCEPTED &&
            installationData?.networkTag === 'MOD'
          ? [tasks, deviations]
          : [showMyPlan ? myplan : myday, tasks, deviations];
      return menu;
    }
    case ActivityDifferentiator.CMSN:
      return status === InstallationStatus.FOR_TESTER_ACCEPTANCE
        ? [showMyPlan ? myplan : myday, starting, deviations]
        : [showMyPlan ? myplan : myday, tasks, deviations];
    case ActivityDifferentiator.SEEN:
      return [showMyPlan ? myplan : myday, tasks, deviations];
    default:
      return [myday, tasks, deviations];
  }
};

export default getMenus;
