import React from 'react';
import {
  createStyles,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DialogType } from '../TeamList';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../schemas';
import { DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    assigmentInfo: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '& p': {
        cursor: 'pointer',
      },
      textTransform: 'uppercase',
    },
  })
);
export type AddButtonProps = {
  contact: Contact;
  setSelectedItem: (item: Contact) => void;
  setShowDialog: (dialog: DialogType) => void;
  setItemToRemove: (item: Contact) => void;
};

const ContactInfoDetails = (props: AddButtonProps): JSX.Element => {
  const classes = useStyles();
  const { contact } = props;
  const { setSelectedItem, setShowDialog, setItemToRemove } = props;
  const { t } = useTranslation();
  return (
    <Grid container className={classes.assigmentInfo}>
      <Grid item xs={5}>
        <Typography>{t(`contactRoles.${contact.role}`)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <Link
            onClick={() => {
              setSelectedItem(contact);
              setShowDialog(DialogType.CONTACT);
            }}
          >
            {contact.firstName && contact.lastName
              ? `${contact.firstName} ${contact.lastName}`
              : contact.firstName || contact.lastName || '-'}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={1} container justify="flex-end" alignContent="flex-end">
        <IconButton
          data-testid={`remove-icon-contact-${contact.guid}`}
          style={{ padding: 0, margin: 0 }}
          onClick={() => {
            setItemToRemove(contact);
            setShowDialog(DialogType.REMOVE);
          }}
        >
          <DeleteForever />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ContactInfoDetails;
