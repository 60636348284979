import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { theme } from '@konecorp/ui-library';

import { Filters } from '../OngoingInstallationsList';

export type NetworkListFiltersProps = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  onClose: (reset?: boolean) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const NetworkListFilters = (props: NetworkListFiltersProps): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { filters, setFilters, onClose } = props;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <FormGroup>
        <FormLabel>{t('myInstallation.show')}</FormLabel>
        <FormControl>
          <FormControlLabel
            name="NBS"
            control={
              <Checkbox
                checked={filters.NBS}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('installationTypes.seKiqc')}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            name="MOD"
            control={
              <Checkbox
                checked={filters.MOD}
                color="primary"
                onChange={handleCheckboxChange}
              />
            }
            label={t('installationTypes.partialMod')}
          />
        </FormControl>
      </FormGroup>
      <Button
        fullWidth
        className={styles.button}
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => onClose()}
      >
        {t('deviationsList.applyButton')}
      </Button>
      <Button
        fullWidth
        className={styles.button}
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => onClose(true)}
      >
        {t('deviationsList.resetButton')}
      </Button>
    </>
  );
};

export default NetworkListFilters;
