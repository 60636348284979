import React, { useContext, useEffect, useState } from 'react';
import { CircleCancel, theme } from '@konecorp/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SubHeader from '../../components/SubHeader';
import InstallationCard from '../InstallationCard';
import Context from '../../context';
import {
  ActivityDifferentiator,
  InstallationCardData,
  Installation,
  InstallationStatus,
} from '../../schemas';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';
import {
  getInstallationCardData,
  sortInstallationsCardData,
} from '../../helpers/getInstallationLists';
import { getKoneEmployeeUserRole } from '../../helpers/getUserRole';
import { Box, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import NetworkListFilters from '../NetworkListFilters';

export enum DialogType {
  FILTERS,
  NONE,
}
export type Filters = {
  NBS: boolean;
  MOD: boolean;
};
export const defaultFilters: Filters = {
  NBS: true,
  MOD: false,
};
const useStyles = makeStyles(() =>
  createStyles({
    content: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      overflow: 'auto',
    },
    headingContainer: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    body: {
      margin: theme.spacing(1),
    },
    closeIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      right: '10px',
    },
  })
);

const OngoingInstallationsList = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [getTokenFunction] = useGetToken();
  const [employeeId] = useGetUserData();
  const [openedDialog, setOpenedDialog] = useState<DialogType>(DialogType.NONE);
  const [installationCardData, setInstallationCardData] = useState<
    InstallationCardData[]
  >([]);
  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
  });
  const { ongoingInstallations, updateIsLoading } = useContext(Context);

  const history = useHistory();
  const filtersCloseAction = (reset?: boolean) => {
    if (reset) setFilters(defaultFilters);
    setOpenedDialog(DialogType.NONE);
  };
  useEffect(() => {
    (async () => {
      if (!ongoingInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (ongoingInstallations && installationCardData.length === 0) {
        updateIsLoading(true);

        const token = await getTokenFunction();
        const promises = ongoingInstallations.map((network) =>
          getInstallationCardData(network, token)
        );

        const ongoinInstallationCardData = await Promise.all(promises);

        setInstallationCardData(
          ongoinInstallationCardData.sort(sortInstallationsCardData)
        );
        updateIsLoading(false);
      }
    })();
  }, [ongoingInstallations]);

  const handleCardClick = (installationCardData: InstallationCardData) => {
    const { network, installation } = installationCardData;
    const role = getKoneEmployeeUserRole({
      installationData: installation as Installation,
      employeeId,
    });

    window.sessionStorage.setItem('previousPageInternalUrl', '/ongoing');

    const path =
      role === ActivityDifferentiator.SPV
        ? 'progress'
        : !installation.isModelData
        ? 'myday'
        : installation?.networkTag === 'MOD' &&
          installation?.status === InstallationStatus.INSTALLER_ACCEPTED
        ? 'installation-tasks'
        : 'execution';
    history.push(`${network.networkNumber}/${path}`);
  };

  return (
    <>
      <SubHeader
        title={t('myInstallation.ongoingInstallations')}
        handleGoBackClick={() => {
          history.push('/');
        }}
      />

      {true && (
        <Grid container className={classes.headingContainer}>
          <Grid item xs={11}>
            <Typography variant={'h5'}>{t('installationNetworkListTitle')}</Typography>
          </Grid>
          <Grid item xs={1}>
            <FilterList
              data-testid="icon-filters"
              onClick={() => setOpenedDialog(DialogType.FILTERS)}
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={openedDialog !== DialogType.NONE} fullWidth maxWidth="sm">
        <DialogContent>
          <CircleCancel
            data-testid="dialog-close-icon"
            className={classes.closeIcon}
            onClick={() => setOpenedDialog(DialogType.NONE)}
          />
          <Box mt={1} padding={theme.spacing(0.5)}>
            {openedDialog === DialogType.FILTERS && (
              <NetworkListFilters
                filters={filters}
                setFilters={setFilters}
                onClose={filtersCloseAction}
              />
            )}
          </Box>{' '}
        </DialogContent>
      </Dialog>

      {installationCardData && (
        <div className={classes.content}>
          {installationCardData
            .filter((cardData) => {
              const { networkTag } = cardData.network || {};
              if (cardData.network.isVariationOrder) return false;
              if (filters.NBS && (networkTag === 'NBS' || networkTag === '')) return true;
              if (filters.MOD && networkTag === 'MOD') return true;
              if (
                filters &&
                networkTag !== 'NBS' &&
                networkTag !== 'MOD' &&
                networkTag !== ''
              )
                return true;
              return false;
            })
            .map((installationCardData: InstallationCardData, index: number) => {
              const { network, deviations, installation } = installationCardData;
              return (
                <InstallationCard
                  key={`ongoing-installation-card-${index}`}
                  network={network}
                  deviations={deviations}
                  status={installation.status}
                  role={getKoneEmployeeUserRole({
                    installationData: installation as Installation,
                    employeeId,
                  })}
                  firstAssignmentDate={installation.firstAssignmentDate}
                  latestAnsweredQdQuestionSet={installation.latestAnsweredQdQuestionSet}
                  hasQualityForm={installation.hasQualityForm}
                  onClick={() => handleCardClick(installationCardData)}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

export default OngoingInstallationsList;
