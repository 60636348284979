import React, { PropsWithChildren, useEffect, useState } from 'react';
import { theme } from '@konecorp/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ExtendedInstallation, Installation } from '../../schemas';
import SubHeader from '../../components/SubHeader';
import TeamList from '../../components/TeamList';
import { DatePicker } from '../DatePicker';
import { API_TYPE, put } from '../../helpers/fetch';
import { useGetToken } from '../../hooks/useGetToken';

export type SupervisorNewInstallationProps = {
  installation: Installation | ExtendedInstallation;
  networkNumber: string;
};
const useStyles = makeStyles((t: Theme) =>
  createStyles({
    content: {
      marginLeft: t.spacing(2),
      marginRight: t.spacing(2),
      paddingTop: t.spacing(3),
      height: '100%',
    },
    greenHeadline: {
      backgroundColor: t.palette.success.light,
      padding: t.spacing(1),
    },
    blueHeadline: {
      backgroundColor: t.palette.info.light,
      padding: t.spacing(1),
    },
    grayHeadline: {
      backgroundColor: '#E0E0E0',
      padding: t.spacing(1),
    },
    assigmentInfo: {
      paddingLeft: t.spacing(3),
      paddingBottom: t.spacing(1),
      paddingTop: t.spacing(1),
    },
    extraInfo: {
      marginTop: t.spacing(2),
      '& > div': {
        paddingLeft: t.spacing(3),
        paddingTop: t.spacing(2),
      },
    },
    buttonRow: {
      paddingLeft: 0,
    },
    upperCase: {
      textTransform: 'uppercase',
    },
    closeDialogIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      zIndex: 1,
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
    },
  })
);

const SupervisorNewInstallation = (
  props: PropsWithChildren<SupervisorNewInstallationProps>
): JSX.Element => {
  const { installation, networkNumber } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [newDate, setNewDate] = useState(true);
  const [inspectDate, setInspectDate] = useState(
    new Date(installation.inspectionDate ?? Date.now())
  );
  const [getTokenFunction] = useGetToken();
  const putInsDateSoldHrs = async (inspectionDate: string): Promise<void> => {
    const token = await getTokenFunction();
    return await put(
      `v1/installations/${networkNumber}/insDateSoldHours`,
      token,
      API_TYPE.APPLICATION,
      { type: 'inspectionDate', value: inspectionDate }
    );
  };
  const handleChecklistButtonClick = async (): Promise<void> => {
    try {
      history.push(`/${networkNumber}/pre-install-checklist`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };

  const handleInstallPlanButtonClick = async (): Promise<void> => {
    try {
      history.push(`/${networkNumber}/installation-plan`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };
  useEffect(() => {
    if (installation.inspectionDate !== null) {
      setNewDate(false);
    }
  }, []);
  const classes = useStyles(theme);
  const handleDateChange = async (ISOFormattedDate: string) => {
    installation.inspectionDate = ISOFormattedDate;
    if (ISOFormattedDate) {
      setInspectDate(new Date(ISOFormattedDate));
      await putInsDateSoldHrs(ISOFormattedDate);
    } else {
      setNewDate(true);
    }
  };
  const renderCustomerInfo = (networkNumber: string): JSX.Element => {
    const {
      customerName1 = '',
      street = ' ',
      houseNumber = '',
      city = '',
      cityPostalCode = '',
      countryKey = '',
      partnerFunction = '',
    } = installation.customer ?? {};
    if (partnerFunction !== 'WE') {
      return <></>;
    }
    return (
      <>
        <Grid container direction="row" spacing={2}>
          <Grid container item>
            <Grid item xs={6} md={2}>
              <Typography className={classes.upperCase}>
                {t('deviationsTable.network')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography>{networkNumber}</Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={6} md={2}>
              <Typography className={classes.upperCase}>
                {t('supervisorNewInstallation.customer')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography>{customerName1}</Typography>
              <Typography>
                {street} {houseNumber}
              </Typography>
              <Typography>
                {cityPostalCode} {city}, {countryKey}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={6} md={2}>
              <Typography className={classes.upperCase}>
                {t('supervisorNewInstallation.inspectionDate')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              {newDate ? (
                <>
                  <DatePicker
                    name="Inspection Date"
                    dateTime={inspectDate.toISOString()}
                    onDateSelected={handleDateChange}
                    label={t('supervisorNewInstallation.inspectionDate')}
                    newDate={newDate}
                    setNewDate={setNewDate}
                  />
                </>
              ) : (
                <>
                  <DatePicker
                    name="Inspection Date"
                    dateTime={inspectDate.toISOString()}
                    onDateSelected={handleDateChange}
                    label={t('supervisorNewInstallation.inspectionDate')}
                    newDate={newDate}
                    setNewDate={setNewDate}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderButton = (): JSX.Element => {
    return (
      <>
        <Grid item className={classes.extraInfo}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.buttonRow}
          >
            <Grid>
              {installation?.networkTag === 'MOD' ? (
                <Button
                  data-testid="installation-button"
                  id="installation-button"
                  variant="contained"
                  color="primary"
                  onClick={handleInstallPlanButtonClick}
                >
                  <Typography>
                    {' '}
                    {t('supervisorNewInstallation.proceedToInstallationPlan')}{' '}
                  </Typography>
                </Button>
              ) : (
                <Button
                  data-testid="start-button"
                  id="start-button"
                  variant="contained"
                  color="primary"
                  onClick={handleChecklistButtonClick}
                >
                  <Typography>
                    {' '}
                    {t(
                      'supervisorNewInstallation.proceedToPreInstallationChecklist'
                    )}{' '}
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <SubHeader
        title={t('myInstallation.toBeStartedInstallations')}
        handleGoBackClick={() => {
          history.push('/tobestarted');
        }}
      />
      <div className={classes.content}>
        <Grid container spacing={2}>
          {renderCustomerInfo(networkNumber)}
          <TeamList installation={installation} networkNumber={networkNumber} />
        </Grid>
        {renderButton()}
      </div>
    </>
  );
};
export default SupervisorNewInstallation;
