import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import AlertBox from '../AlertBox';
import {
  getOpenDeviationsCount,
  getUnansweredQuestionsCount,
} from '../../helpers/question';
import {
  ActivityDifferentiator,
  Deviation,
  Installation,
  InstallationStatus,
} from '../../schemas';
import { CompareStatusResult, compareStatus } from '../../helpers/getInstallationLists';

const getQuestionCount = (
  installation?: Installation | null,
  role?: ActivityDifferentiator
): number => {
  const installationStatus = installation?.status || InstallationStatus.TO_BE_STARTED;

  const acceptedStatus =
    role === ActivityDifferentiator.INST
      ? InstallationStatus.INSTALLER_ACCEPTED
      : InstallationStatus.TESTER_ACCEPTED;

  const isBeforeAccepted =
    compareStatus(installationStatus, acceptedStatus) === CompareStatusResult.BEFORE;

  if (isBeforeAccepted) return 0;

  const questionsAndAnswers =
    role === ActivityDifferentiator.INST
      ? {
          questions: installation?.installerQuestions ?? [],
          answers: installation?.installerAnswers ?? [],
        }
      : {
          questions: installation?.testerQuestions ?? [],
          answers: installation?.testerAnswers ?? [],
        };

  return getUnansweredQuestionsCount(questionsAndAnswers);
};

export type HandoverNotificationsProps = {
  installation?: Installation | null;
  deviations?: Deviation[];
  showQdPlusQuestionsWarning?: boolean;
  showQualityReviewQuestionsWarning?: boolean;
};

const HandoverNotifications = ({
  installation,
  deviations = [],
  showQdPlusQuestionsWarning,
  showQualityReviewQuestionsWarning,
}: HandoverNotificationsProps): JSX.Element => {
  const { t } = useTranslation();

  const openDeviationsCount = getOpenDeviationsCount(deviations);
  const showOpenDeviationsCount = openDeviationsCount > 0;

  const unansweredQdPlusQuestionCount = getQuestionCount(
    installation,
    ActivityDifferentiator.INST
  );
  const showUnansweredQdPlusQuestionCount =
    unansweredQdPlusQuestionCount > 0 && showQdPlusQuestionsWarning;

  const unansweredQualityReviewQuestionCount = getQuestionCount(
    installation,
    ActivityDifferentiator.CMSN
  );
  const showUnansweredQualityReviewQuestionCount =
    unansweredQualityReviewQuestionCount > 0 && showQualityReviewQuestionsWarning;

  if (
    (showOpenDeviationsCount ||
      showUnansweredQdPlusQuestionCount ||
      showUnansweredQualityReviewQuestionCount) &&
    (installation?.networkTag !== 'MOD' ||
      installation?.status !== InstallationStatus.INSTALLER_ACCEPTED)
  )
    return (
      <AlertBox>
        {showOpenDeviationsCount && (
          <Typography data-testid="openDeviations">
            {t('handoverNotifications.openDeviations', { openDeviationsCount })}
          </Typography>
        )}
        {showUnansweredQdPlusQuestionCount && (
          <Typography data-testid="unansweredQdPlusQuestions">
            {t('handoverNotifications.unansweredQdPlusQuestions', {
              unansweredQdPlusQuestionCount,
            })}
          </Typography>
        )}
        {showUnansweredQualityReviewQuestionCount && (
          <Typography data-testid="unansweredQualityReviewQuestions">
            {t('handoverNotifications.unansweredQualityReviewQuestions', {
              unansweredQualityReviewQuestionCount,
            })}
          </Typography>
        )}
      </AlertBox>
    );

  return <></>;
};

export default HandoverNotifications;
